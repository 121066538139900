<template>
  <CmsButtonBase
    :aria-label="alternativeLabel"
    target="_blank"
    :to="url"
    variant="primary"
    width="fixed"
  >
    {{ i18n(translations.pdfLink) }}
  </CmsButtonBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { Pdf } from '@backmarket/http-api/src/api-specs-content/models/legal-page-content'
import CmsButtonBase from '@backmarket/nuxt-layer-cms/CmsButtonBase.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './PDFLink.translations'

const props = defineProps<Pdf>()

const i18n = useI18n()

const alternativeLabel = computed(
  () => `${props.title} ${i18n(translations.pdfLink)}`,
)
</script>
